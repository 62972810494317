* {margin: 0; padding: 0;}
body{font-size: 1em; font-family: $font; font-weight: 300; width: 100%; color: $black;
  -webkit-font-smoothing: subpixel-antialiased; text-rendering: optimizeLegibility; -webkit-overflow-scrolling: touch;
  & > span {
    display: inline;
  }
}
a {text-decoration: none; outline: none; cursor: pointer; display: inline-block; @include trans; color: $black;}
img {display: block; width: 100%; height: auto;}
picture {width: 100%; display: block; line-height: 0;}
span, b, del, i {display: inline-block;}
ul, ol, li {list-style-type: none;}
label {display: inline-block; cursor: pointer;}
input, textarea {@include no-form; @include trans; width: 100%; font-size: 16px; font-family: $font; font-weight: 300;}
input[type="text"], input[type="email"], input[type="password"], textarea {@include padblock(0.6em); border: 1px solid $light; width: 100%;
  &:focus {border-color: $blue;}
}
button {cursor: pointer; @include no-form; font-size: 1em; font-weight: 300; font-family: $font;}
select {cursor: pointer; width: 100%; font-size: 16px; font-weight: 300; font-family: $font; @include no-form; @include trans;
  &::-ms-expand {  display: none; }
}
main {width: 100%; display: block; padding-top: 130px; position: relative;
  @include tablet {padding-top: 91px;}
  &.checkout {padding-top: 1em;}
  min-height: 75vh;
}

#flix-inpage  img {
  width: unset !important;
}

#flix-inpage  div {
  max-width: unset !important;
}

.radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* hide the browser's default radio button */
.radio input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* create custom radio */
.radio .check {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 3px solid #ccc;
  border-radius: 50%;
}

/* on mouse-over, add border color */
.radio:hover input[type="radio"] ~ .check {
  border: 3px solid #0097A9;
}

/* add background color when the radio is checked */
.radio input[type="radio"]:checked ~ .check {
  background-color: #fff;
  border: 3px solid #ccc;
}

/* create the radio and hide when not checked */
.radio .check:after {
  content: "";
  position: absolute;
  display: none;
}

/* show the radio when checked */
.radio input[type="radio"]:checked ~ .check:after {
  display: block;
}

/* radio style */
.radio .check:after {
  top: 3px;
  left: 3px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #0097a9;
}

.pp {
  display: block
}

.pp__row {
  display: flex
}

.pp__block {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom:10px
}

.pp__block_simple {
  flex: inherit;
  position: relative
}

.pp__label {
  margin: 0 5px;
  font-size: 14px
}

.pp__input {
  margin: 5px;
  padding: 10px 0;
  text-align: center;
  border-radius: 10px;
  border: 1px solid gray
}

.pp__card-brand {
  display: none;
  margin: -40px 12px 8px auto;
  max-width: 30px;
}

.pp__error {
  margin: 0 5px;
  color: red;
  display: none
}

.pp__help-icon {
  display: block;
  color: white;
  background: red;
  text-align: center;
  max-width: 10px;
  max-height: 18px;
  padding: 5px 10px;
  border-radius: 50%;
  margin: 35px 0 8px -40px
}

.pp__help-box {
  position: absolute;
  left: -84px;
  top: -26px;
  display: none;
  flex-direction: column
}

.pp__help-image {
  padding: 0 3px;
  border: 1px solid #d4d4d4;
  background: white
}

.pp__help-triangle {
  width: 0;
  height: 0;
  margin-left: auto;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #d4d4d4
}

.pp__submit {
  width: 100%;
  margin: 5px 0;
  padding: 10px 0;
  border: 0;
  border-radius: 10px;
  font-size: 20px;
  color: white;
  background: #414042
}

.pp__submit:hover {
  cursor: pointer
}

.modal--form::-webkit-scrollbar { /* WebKit */
  width: 5px!important;
}

.margin-top{
  margin-top: 3em;
}

.selected{
  background-color: $blue!important;
  color: white!important;
}

.wrapMenu{
  width: 90%;
  max-width: 900px;
  margin: 0 auto;
  display: none;
  flex-flow: row;
  justify-content: space-between;
  font-family: $font;
  z-index: 10;
  position: absolute;
  margin-top: 4em;
  background: white;
}

.wrapMenu ul {
  padding: 0;
  margin: 0 20px 0 0;
  list-style: none;
  border: 1px solid #e9e9e9;
  display: flex;
  border-radius: 5px;
  flex-flow: column;
  flex: 25%;
  justify-content: initial;
  box-shadow: 0 0 10px #e9e9e9;
}
.wrapMenu ul li a {
  text-decoration: none;
  width: 100%;
  padding: 20px 0;
  border: 1px solid;
  text-indent: 10px;
  display: flex;
  justify-content: space-between;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  color: #afafaf;
  transition: all 0.5s ease;
}

.wrapMenu ul li a:hover {
  color: #da291c;
}

.wrapMenu ul li:last-child a {
  border-bottom: 0;
}
.wrapMenu ul li a i {
  margin: 0 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapMenu .wrapMenuPrincipal {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border: 1px solid #e9e9e9;
  flex: 75%;
  border-radius: 5px;
  padding: 10px 20px;
  box-shadow: 0 0 10px #e9e9e9;
}
.wrapMenu .wrapMenuPrincipal ul {
  border: 0;
  padding: 0;
  margin: 0;
  justify-content: flex-start;
  box-shadow: none;
}
.wrapMenu .wrapMenuPrincipal ul li:first-child {
  color: #da291c;
  text-indent: 10px;
  font-weight: 800;
}
.wrapMenu .wrapMenuPrincipal ul li {margin: 0 0 10px 0;}
.wrapMenu .wrapMenuPrincipal ul li a {
  padding: 0;
  border: 0 solid;
  color: #afafaf;
  font-size: 0.96em;
}

.wrapMenu .wrapMenuPrincipal ul li a {
  transition: all 0.5s ease;
}

.wrapMenu .wrapMenuPrincipal ul li a:hover {
  color: #da291c;
}

.blur-content{
  filter: blur(3px);
}

.flex-display{
  display: flex!important;
}

#search-box{
  width: 95%;
}

#grid{
  @include phone {padding-top: 4em;}
}

.max-width{
  width: 31%!important;
}

.category-description h1{
  font-family: $font;
  font-size: 2em;
  color: #BBBBBB;
  font-weight: 100;
}

span.category-description-second {
  font-family: $font;
  font-size: 24px;
  color: #666666;
  font-weight: bold;
}

.product--box--plan .product--box--title {
  min-height: 50px;
}

.product--box--plan .product--box--price {
   min-height: 135px;
}
.product--box .product--box--title {
  min-height: 100px;
}

.product--box .product--box--price {
   min-height: 135px;
   flex-grow: 1;
}

.subtitle {
  color: $blue;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}

.recurrency {
  color: $red;
}

.PlanIncluded{
  margin-bottom: 1em;
}

.margin-less {
  margin-bottom: 0.5em!important;
}


.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: .5s;
  padding-top: 60px;
  border-right-style: solid;
  border-color: gray;
}

.sidenav a {
padding: 8px 8px 8px 32px;
text-decoration: none;
font-size: 25px;
color: #818181;
display: block;
transition: 0.3s;
}

.sidenav a:hover {
color: #f1f1f1;
}

.sidenav .closebtn {
position: absolute;
top: 0;
right: 25px;
font-size: 36px;
margin-left: 50px;
}

@media screen and (max-height: 450px) {
.sidenav {padding-top: 15px;}
.sidenav a {font-size: 18px;}
}

.Collapsible {
  background-color: white;
  color: gray;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.active, .Collapsible:hover {
  background-color: transparent;
}

.Collapsible span {
  width: 90%;
  padding-left: 14px;
}

.Collapsible span:after {
  content: '\203A';
  color: gray;
  font-weight: bold;
  float: right;
  margin-left: 5px;
  font-size: 21px;
  position: relative;
  top: -4px;
}

.Collapsible span.is-open:after {
  content: "\2304";
   color:red!important;
}

.content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.active, .Collapsible:hover {
  color: red;
  font-weight: bold;
}

.alert{
  display: flex;
  line-height: 26pt;
}

.p-alert-message{
  padding-left: 5px;
}
.product--datasheet h6{
  color: black!important;
  font-weight: bold;
}

.product--datasheet p{
  color: black!important;
  font-weight: 100;
}

@media(max-width: 700px){

  .blur-content {
      filter: blur(0px)!important;
  }

  /*botones categorias home*/
  span.nav--category-box,
  a.nav--category-box {
      width: fit-content;
      min-width: 30%;
      font-size: 12px;
      float: left;
      height: 32px;
      padding: 3px;
      text-align: center;
      margin: 10px 4px 0 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
  }

  .catalog--banner {
      width: 100%;
      margin-bottom: 1em;
      height: 100px;
      margin-top: 20px;
  }

  .slick-slide {
      height: 110px;
  }
  .catalog--banner .slick-arrow svg {
      width: 22px !important;
      height: 32px !important;
      top: -7px !important;
      position: relative;
  }

  .PlanIncluded {
    margin-bottom: 1em;
    padding-left: 22px;
    font-size: 20px;
  }

  .product .slick-slider li a img{
    width: 80%;
    display: block;
    margin: 0 auto;
    padding: 0;
    padding-left: 0!important;
    height: 46px;
    object-fit: contain;
  }

  .Collapsible {
    background-color: #fff;
    color: gray;
    cursor: pointer;
    padding: 0;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    padding-top: 26px;
    padding-bottom: 0;
    border-bottom-style: solid;
    vertical-align: middle;
    border-width: 1px;
  }

  .Collapsible span:after {
    content: "\203A"!important;
    color: gray;
    font-weight: 700;
    float: right;
    margin-left: 5px;
    font-size: 21px;
    position: relative;
    top: -4px;
  }

  .Collapsible span {
    width: 90%;
    padding-left: 14px;
    height: 38px;
    vertical-align: inherit;
    top: -2px;
    position: relative;
  }

  .active, .Collapsible:hover {
    color: red;
    font-weight: 700;
    border-color: gray;
  }

  .sidenav {
    display:none;
    height: 100%;
    width: 250px;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    background-color: #fff;
    transition: .5s;
    padding-top: 0;
    border-right-style: solid;
    border-color: gray;
    overflow: inherit;
  }

  .mobileli {
    width: 100%;
    list-style: none;
    height: 50px;
    background: #f2f2f2;
    color: gray!important;
    padding-top: 0!important;
    padding-left: 10%!important;
    font-size: 15px!important;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: #afafaf;
    line-height: 54px;
  }

  .sidenav .closebtn {
    position: absolute;
    top: 2px;
    right: -18px;
    margin-left: 50px;
    background: white;
    border-radius: 43px;
    padding: inherit;
    width: 25px;
    height: 25px;
    font-size: 24px;
    text-align: center;
    color: red;
    z-index: 9999;
    border-style: solid;
    border-color: gray;
    border-width: 1px;
  }

  .menuContainer {
    width: 100%;
  }

  .category-description h1{
    font-family: $font;
    font-size: 20px;
    color: #BBBBBB;
    font-weight: 100;
  }

  span.category-description-second {
    font-family: $font;
    font-size: 16px;
    color: #666666;
    font-weight: bold;
  }

}
.no-availability .not-stock{
  font-size: 13px;
}
.product--info .not-stock{
font-size: 13px;
}
#kampyleButtonContainer {
  display: none;
}
